import React from 'react';
import './css/App.css';
import {useEffect} from "react";
import { AuthProvider } from './authentication/auth_context';
import {Menu} from "./menu/menu";
import {Router} from "./router";

function App() {

    useEffect(() => {
        const enterFullscreen = () => {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            }
        };

        enterFullscreen();
    }, []);

    useEffect(() => {
        const hideAddressBar = () => {
            // @ts-ignore
            if (window.navigator.standalone) return;
            if (window.scrollY < 1) {
                window.scrollTo(0, 1);
            }
        };

        window.addEventListener('load', hideAddressBar);
        window.addEventListener('orientationchange', hideAddressBar);

        return () => {
            window.removeEventListener('load', hideAddressBar);
            window.removeEventListener('orientationchange', hideAddressBar);
        };
    }, []);

  return (
    <div className="App">
      <AuthProvider>
        <Router />
        <Menu />

      </AuthProvider>
    </div>
  );
}

export default App;
