import {Review} from "./SRinterfaces";
import {postReviewProcessing} from "../api/api";
import {SRCard} from "./interface";
import {useEffect, useState} from "react";

interface EvaluationBarProps {
    side: string;
    setSide: Function;
    switchActiveCard: Function;
    activeCard: SRCard|null|undefined;
}

export function EvaluationBar({ side, setSide, switchActiveCard, activeCard }: EvaluationBarProps) {
    const [maxScore, setMaxScore] = useState<number>(0);
    const [scoreRange, setScoreRange] = useState<number[]>([]);

    useEffect(() => {
        if (activeCard) {
            calculateScores()
        }
    }, [activeCard])

    function calculateScores(): void {
        let max: number;
        // @ts-ignore
        if (activeCard.cardType !== "visualization") {
            // @ts-ignore
            max = activeCard.reviewType === "front" ? activeCard.back.text.split(";").length : activeCard.front.text.split(";").length;
        } else {
            // @ts-ignore
            max = activeCard.back.type === "text" ? 2 : 1;
        }
        setMaxScore(max);
        // @ts-ignore
        setScoreRange([...Array(max + 1).keys()]);

    }

    async function evaluateCard(score: number) {

        if (activeCard) {
            let review: Review = {
                cardID: activeCard.cardID,
                score: score / maxScore,
                easinessFactor: activeCard.easinessFactor,
                reviewType: activeCard.reviewType
            }

            const response = await postReviewProcessing(review);
            if (response && response.status === 200) {
                await switchActiveCard();
            } else {
                alert("Card could not be processed.")
            }
        }

    }

    return (
        <div className="evaluation-bar">
            {side === "front" &&
                <button
                    className="evaluate-btn max-w-fit show-answer py-1 px-20 text-sm font-mono"
                    onClick={() => setSide("back")}
                >
                    show answer
                </button>
            }
            {side === "back" && maxScore && scoreRange &&
                <>
                    {scoreRange.map((score: number, i: number) =>
                        <button
                            key={i}
                            className={`evaluate-btn fail text-sm px-8 py-1 mx-3 font-mono S${score.toString()}`}
                            onClick={() => evaluateCard(score)}
                        >
                            {scoreRange.length < 3 ? (i === 0 ? "fail" : "pass") : score}
                        </button>
                    )}
                </>
            }
        </div>
    )
}