import "./menu.css"
import {Link, useLocation} from "react-router-dom";
import React, {useState, useEffect, useContext} from "react";
import {AuthContext} from "../authentication/auth_context";
import {AuthenticationBar} from "../authentication/authentication_bar";
import { PiCirclesThreeThin, PiAsteriskSimpleDuotone } from "react-icons/pi";
import {AnimatePresence, motion} from "framer-motion";

export function Menu() {
    const [mobileFold, setMobileFold] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [pathName, setPathName] = useState<string>("");
    const [desktopOpen, setDesktopOpen] = useState(false);
    // @ts-ignore
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const loc = useLocation();

    useEffect(() => {
        setPathName(loc.pathname);
        if (window.innerWidth < 400)  {
            setMobile(true);
        } else {
            setMobile(false);
        }
    },)

    function hideDesktopMenu(e:any) {
        console.log(e)
    }

    return (
        <div
            className={`menu-container ${mobileFold ? 'blur' : ''}`}
            onMouseLeave={(e) => setDesktopOpen(false)}
        >

            {(mobileFold || (!mobile && desktopOpen)) &&
                <>
                <AuthenticationBar />
                <AnimatePresence>
                <motion.div
                    className=""
                    tabIndex={0}
                    initial={{x:+100, opacity:0}}
                    animate={{x:0, opacity:1}}
                    exit={{x:+100, opacity:0}}
                >
                    <Link className={`menu-button ${pathName === "/" ? 'active-menu-btn' : ''}`} to="/" onClick={() => setMobileFold(false)}><span className="mb-vis">home</span></Link>
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/reading_list" ? 'active-menu-btn' : ''}`} to="/reading_list" onClick={() => setMobileFold(false)}><span>reading list</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/object_database" ? 'active-menu-btn' : ''}`} to="/object_database" onClick={() => setMobileFold(false)}><span>object db</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/source_tracking" ? 'active-menu-btn' : ''}`} to="/source_tracking" onClick={() => setMobileFold(false)}><span>source tracker</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/add_quote" ? 'active-menu-btn' : ''}`} to="/add_quote" onClick={() => setMobileFold(false)}><span>quotes</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/habit_tracking" ? 'active-menu-btn' : ''}`} to="/planner" onClick={() => setMobileFold(false)}><span>planner</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/spaced_repetition" ? 'active-menu-btn' : ''}`} to="/spaced_repetition" onClick={() => setMobileFold(false)}><span>spaced repetition</span></Link>}
                    {isLoggedIn && <Link className={`menu-button ${pathName === "/search" ? 'active-menu-btn' : ''}`} to="/search" onClick={() => setMobileFold(false)}><span>search</span></Link>}
                    <Link className={`menu-button ${pathName === "/about" ? 'active-menu-btn' : ''}`} to="/about" onClick={() => setMobileFold(false)}><span className="mb-vis">about</span></Link>
                    <Link className={`menu-button ${pathName === "/contact" ? 'active-menu-btn' : ''}`} to="/contact" onClick={() => setMobileFold(false)}><span className="mb-vis">contact</span></Link>
                </motion.div>
                </AnimatePresence>
                </>}
            {(!desktopOpen && !mobile) &&
                <AnimatePresence>
                    <motion.div
                        className="menu-closed-desktop"
                        initial={{x:+100,y:0, opacity:0}}
                        animate={{x:0, y:0, opacity:1}}
                        exit={{x:+100,y:0, opacity:0}}
                    >
                        <PiCirclesThreeThin
                            className="menu-closed-desktop"
                            color={"#fefefe"}
                            size={"40px"}
                            onClick={(e) => setDesktopOpen(!desktopOpen)}
                        />
                    </motion.div>
                </AnimatePresence>}
            <div>
                <button className="button-menu-unfold" onClick={() => setMobileFold(!mobileFold)}><PiAsteriskSimpleDuotone /></button>
            </div>
        </div>
    )
}